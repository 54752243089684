import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const peixe = () => {
  return (
    <Layout>
      <Seo title="Menu - Peixe" defer={false} />
      <div className="w-full mx-auto lg:mt-10 flex flex-col">
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md lg:h-32 lg:text-xl lg:font-medium">
          <h1>Ementa</h1>
          <span className="text-xs font-extralight">
            Coma bem, viva melhor!
          </span>
        </div>
        <div className=" bg-peixe-image w-full h-20 bg-carne-image bg-no-repeat bg-center bg-cover lg:h-48"></div>
        <div className=" w-4/5 mx-auto my-9 lg:my-20">
          <section className="grid grid-cols-1 gap-12 text-center lg:gap-14 lg:grid-cols-4 mb-10  ">
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-80 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/terra-mar-2_wf2snh.jpg"
                  alt=""
                />
              </div>
              <span>Terra e Mar</span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-80 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/filetes-4_oxf9ot.jpg"
                  alt=""
                />
              </div>
              <span>Filetes à Marinheiro</span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-80 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/bacalhau-com-broa-1_f2nu6k.jpg"
                  alt=""
                />
              </div>
              <span>Bacalhau com Broa</span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-90 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/baclhau-monte-1_kert8d.jpg"
                  alt=""
                />
              </div>
              <span>Bacalhau à Monte Alegre</span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-90 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639668244/novas%20fotos/1bMPcTP-4CN5GvCpLKEoWskufDwUuTM8e_wsse2r.jpg"
                  alt=""
                />
              </div>
              <span>Polvo à Lagareiro</span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-90 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639668244/novas%20fotos/1b1h6WxXJBmoc13ynEAKLsOerKH47dEhu_qpiwha.jpg"
                  alt=""
                />
              </div>
              <span>Bacalhau à Lagareiro</span>
            </div>
          </section>
        </div>

        <Link to="/menu" className="mx-auto mb-5">
          <button
            type="button"
            className=" text-xs font-medium shadow-md h-8 px-2  text-white transition-colors duration-150 bg-yellow-500 rounded-sm focus:shadow-outline hover:bg-yellow-600 md:px-5 md:h-8"
          >
            Ir para o menu
          </button>
        </Link>
      </div>
    </Layout>
  )
}

export default peixe
